import { TechnologyCategory } from 'modules/ui';
import { backend } from 'modules/career-page/components/TechnologiesSection/content';

import type { MessageKey } from 'modules/intl';

import { webTechnologiesContent } from '../web-development-page';
import { mobileTechnologiesContent } from '../mobile-development-page';

const ids = [1, 2, 3] as const;

export interface Column {
    id: number;
    title: MessageKey;
    description: MessageKey;
}
export interface DevelopmentSectionContent {
    title: MessageKey;
    description: MessageKey;
    technologies: TechnologyCategory;
    image: string;
}

export const whyColumns: Column[] = ids.map(id => ({
    id,
    title: `appDevelopmentPage.why.column${id}.title`,
    description: `appDevelopmentPage.why.column${id}.description`,
}));

const backendTechnologiesContent: TechnologyCategory = {
    name: backend.name,
    items: backend.items,
    sectionTitle: 'appDevelopmentPage.developmentSection.backend.technologies.sectionTitle',
};

export const development: { [category: string]: DevelopmentSectionContent } = {
    mobile: {
        title: 'appDevelopmentPage.developmentSection.mobile.title',
        description: 'appDevelopmentPage.developmentSection.mobile.description',
        technologies: mobileTechnologiesContent,
        image: 'app-development/development-section/mobile.png',
    },
    web: {
        title: 'appDevelopmentPage.developmentSection.web.title',
        description: 'appDevelopmentPage.developmentSection.web.description',
        technologies: webTechnologiesContent,
        image: 'app-development/development-section/web.png',
    },
    backend: {
        title: 'appDevelopmentPage.developmentSection.backend.title',
        description: 'appDevelopmentPage.developmentSection.backend.description',
        technologies: backendTechnologiesContent,
        image: 'app-development/development-section/backend.png',
    },
} as const;
